import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui'
import { cn } from '@/lib/utils'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro'
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro'
import { CarIcon, Star } from 'lucide-react'

const columns = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <div className='h-full w-full flex justify-center items-center'>
          {GRID_DETAIL_PANEL_TOGGLE_COL_DEF?.renderCell?.(params)}
        </div>
      )
    }
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <div className='flex justify-start items-center h-full'>
          {params.row.isMarkedImportant ? <Star size={16} className='fill-yellow-400 stroke-0' /> : ''}
          {params.row.name}
        </div>
      )
    }
  },
  {
    field: 'unitsSoldInDateRange',
    headerName: 'Sales',
    flex: 0.5,
    renderCell: (params: GridRenderCellParams) => (
      <div className='flex justify-start items-center h-full'>{params.value.toLocaleString()}</div>
    )
  },
  {
    field: 'targetDaysSupply',
    headerName: 'Target days supply',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <div className='flex justify-start items-center h-full'>{`${params.value} days` ?? 'N/A'}</div>
    )
  },
  {
    field: 'daysSupply',
    headerName: 'Current days supply',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <div className='flex justify-start items-center h-full'>{params.value ? `${params.value} days` : 'N/A'}</div>
    )
  },
  {
    field: 'targetAmount',
    headerName: 'Optimal inventory',
    flex: 1,
    sortComparator: (v1: number, v2: number) => Math.abs(v1) - Math.abs(v2),
    renderCell: (params: GridRenderCellParams) => {
      const lowerBound = Math.max(0, Math.round(params.row.targetAmount - Math.abs(params.row.confidenceMargin)))
      const upperBound = Math.max(1, Math.round(params.row.targetAmount + Math.abs(params.row.confidenceMargin)))
      return (
        <div className='flex justify-start items-center h-full'>
          {Math.round(params.row.confidenceMargin) === 0
            ? Math.max(1, Math.round(params.row.targetAmount))
            : `${lowerBound}-${upperBound}`}
        </div>
      )
    }
  },
  {
    field: 'unitsInStock',
    headerName: 'Current inventory',
    flex: 1,
    sortComparator: (v1: number, v2: number) => Math.abs(v1) - Math.abs(v2),
    renderCell: (params: GridRenderCellParams) => (
      <div className='flex justify-start items-center h-full'>{params.value ?? 0}</div>
    )
  },
  {
    field: 'confidenceMargin',
    headerName: 'Result confidence',
    flex: 1,
    sortComparator: (v1: number, v2: number) => Math.abs(v1) - Math.abs(v2),
    renderCell: (params: GridRenderCellParams) => {
      const getIndicator = () => {
        if (Math.round(Math.abs(params.value)) > 5)
          return <span className='text-red-800 h-full flex items-center'>Very low</span>
        if (Math.round(Math.abs(params.value)) > 4)
          return <span className='text-red-500 h-full flex items-center'>Low</span>
        if (Math.round(Math.abs(params.value)) > 3)
          return <span className='text-orange-500 h-full flex items-center'>Sparse</span>
        if (Math.round(Math.abs(params.value)) > 2)
          return <span className='text-yellow-500 h-full flex items-center'>Moderate</span>
        if (Math.round(Math.abs(params.value)) > 1)
          return <span className='text-lime-500 h-full flex items-center'>High</span>
        if (Math.round(Math.abs(params.value)) === 0)
          return <span className='text-blue-500 h-full flex items-center'>Exact</span>
        return <span className='text-green-500 h-full flex items-center'>Very High</span>
      }
      return (
        <Tooltip delayDuration={0}>
          <TooltipTrigger>{getIndicator()}</TooltipTrigger>
          <TooltipContent side='bottom' className='max-w-md z-10'>
            The optimal count of {params.row.name} units in stock is{' '}
            {Math.round(params.row.confidenceMargin) === 0
              ? `exactly ${Math.max(1, Math.round(params.row.targetAmount))}`
              : `between ${Math.max(
                  0,
                  Math.round(params.row.targetAmount - Math.abs(params.row.confidenceMargin))
                )} and ${Math.max(1, Math.round(params.row.targetAmount + Math.abs(params.row.confidenceMargin)))}`}
            , due to a confidence margin of ± {Math.round(Math.abs(params.row.confidenceMargin))} based on the amount of
            sales.
          </TooltipContent>
        </Tooltip>
      )
    }
  },
  {
    field: 'amountOffset',
    headerName: 'Stocking recommendation',
    flex: 5,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Tooltip delayDuration={0}>
          <TooltipTrigger>
            <div className='flex flex-start gap-0 items-center flex-wrap w-full h-full'>
              {Array.from({
                length: Math.max(
                  params.row.unitsInStock,
                  Math.round(params.row.targetAmount) - Math.abs(Math.round(params.row.confidenceMargin))
                )
              }).map((_, index) => {
                let fillColor = 'fill-foreground'
                let strokeColor = 'stroke-foreground'
                if (
                  index + 1 <= params.row.unitsInStock &&
                  index + 1 > Math.round(params.row.targetAmount) + Math.abs(Math.round(params.row.confidenceMargin))
                ) {
                  if (
                    index + 1 <=
                    Math.round(params.row.targetAmount) +
                      Math.abs(Math.round(params.row.confidenceMargin)) +
                      params.row.acceptableOverflowMargin
                  ) {
                    fillColor = 'fill-yellow-600'
                    strokeColor = 'stroke-yellow-600'
                  } else {
                    fillColor = 'fill-red-500'
                    strokeColor = 'stroke-red-500'
                  }
                }
                if (index >= params.row.unitsInStock) {
                  fillColor = 'fill-transparent'
                }
                return (
                  <div className={cn('w-8 h-6 flex flex-col items-center justify-center relative gap-2 border-black')}>
                    <CarIcon className={cn(fillColor, strokeColor)} />
                  </div>
                )
              })}
            </div>
          </TooltipTrigger>
          <TooltipContent side='bottom' className='z-10 bg-border'>
            <ul>
              <li className='flex items-center text-foreground gap-2'>
                <CarIcon className='fill-foreground stroke-foreground' /> In stock and within optimal range
              </li>
              <li className='flex items-center text-foreground gap-2'>
                <CarIcon className='fill-yellow-600 stroke-yellow-600' /> Overstocked but within acceptable 10% overflow
                margin
              </li>
              <li className='flex items-center text-foreground gap-2'>
                <CarIcon className='fill-red-500 stroke-red-500' /> Overstocked and exceeds the acceptable 10% overflow
                margin
              </li>
              <li className='flex items-center text-foreground gap-2'>
                <CarIcon className='fill-transparent stroke-foreground' /> Not in stock and should be ordered.
              </li>
            </ul>
          </TooltipContent>
        </Tooltip>
      )
    }
  }
]

const initialState: GridInitialStatePro = {
  sorting: {
    sortModel: [
      {
        field: 'unitsInStock',
        sort: 'desc'
      }
    ]
  }
} satisfies GridInitialStatePro

export { columns, initialState }
