import DataCard from '../data-card'
import SectionCard from '../section-card'
import { TooltipProvider } from '@/components/ui'
import { InventoryOverviewData } from '@/services/api/v2/companies/[companyId]/analytics/inventory-overview'

type InventoryDataSectionProps = {
  data: InventoryOverviewData[] | undefined
  loading: boolean
  excludeTiles?: ('TotalInventory' | 'TotalInvestment' | 'AverageInvestment' | 'DaysSupply' | 'AverageAge')[]
}

export default function InventoryDataSection({ data, loading, excludeTiles = [] }: InventoryDataSectionProps) {
  return (
    <SectionCard title='Inventory Overview' description={`All current inventory data`}>
      <TooltipProvider>
        <div className='w-full grid lg:flex grid-cols-3 gap-2 md:gap-4 p-0 pb-1'>
          {!excludeTiles.includes('TotalInventory') && (
            <DataCard
              title='Total inventory'
              data={data}
              dataKey='TotalInventory'
              type='number'
              loading={loading}
              color='primary'
            />
          )}
          {!excludeTiles.includes('TotalInvestment') && (
            <DataCard
              title='Total investment'
              data={data}
              dataKey='TotalInvestment'
              type='currency'
              loading={loading}
            />
          )}
          {!excludeTiles.includes('AverageInvestment') && (
            <DataCard
              title='Avg investment'
              data={data}
              dataKey='AverageInvestment'
              type='currency'
              loading={loading}
            />
          )}
          {!excludeTiles.includes('DaysSupply') && (
            <DataCard
              title='Days supply'
              data={data}
              dataKey='DaysSupply'
              type='number'
              loading={loading}
              explanation='Days supply in inventory based on the last 30 days of sales'
            />
          )}
          {!excludeTiles.includes('AverageAge') && (
            <DataCard title='Avg age' data={data} dataKey='AverageAge' type='number' loading={loading} />
          )}
        </div>
      </TooltipProvider>
    </SectionCard>
  )
}
